import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap, throwError, of } from 'rxjs';
import { Logger } from '@gci/helpers/logger';

export class ServiceError extends Error {
    constructor(
        message: string,
        public operation: string,
        public endpoint: string,
        public originalError: any
    ) {
        super(message);
        this.name = 'ServiceError';
        // Maintains proper stack trace for where error was thrown
        Error.captureStackTrace(this, ServiceError);
    }
}

export interface BaseEntity {
    id: string;
    [key: string]: any;
}

export interface CrudEndpoints {
    list?: string;
    create?: string;
    update?: (id: string) => string;
    delete?: (id: string) => string;
    [key: string]: any;
}

export type EntityTransformFn<T> = (response: T) => T;


@Injectable()
export abstract class BaseEntityService<T extends BaseEntity> {
    protected logger: Logger;
    protected baseUrl: string;
    protected endpoints: CrudEndpoints;
    protected cacheDuration: number;

    // State management
    protected _allItems: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    protected _activeItem: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
    protected _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private lastFetchTime: number = 0;

    constructor(
        protected httpClient: HttpClient,
        baseUrl: string,
        endpoints: CrudEndpoints,
        serviceName: string,
        cacheDuration: number = 5 * 60 * 1000,
    ) {
        this.baseUrl = baseUrl;
        this.endpoints = endpoints;
        this.cacheDuration = cacheDuration;
        this.logger = new Logger(serviceName);
    }

    // Generic accessors
    get allItems$(): Observable<T[]> {
        return this._allItems.asObservable();
    }

    set allItems(data: T[]) {
        this._allItems.next(data);
    }

    get activeItem$(): Observable<T | null> {
        return this._activeItem.asObservable();
    }

    get activeItemId(): string {
        return this._activeItem.getValue()?.id || '';
    }

    get loading$(): Observable<boolean> {
        return this._loading.asObservable();
    }

    set activeItem(id: string) {
        const allItems = this._allItems.getValue();
        const foundItem = allItems.find(item => item.id === id);
        this._activeItem.next(foundItem || null);
    }

    private isCacheValid(): boolean {
        return Date.now() - this.lastFetchTime < this.cacheDuration;
    }

    // CRUD Operations
    getList(forceRefresh = false): Observable<T[]> {
        // Use cached data if within duration and not forced refresh
        if (!forceRefresh && this.isCacheValid()) {
            return of(this._allItems.getValue());
        }

        this._loading.next(true);
        return this.httpClient.get<{ message: T[] }>(`${this.baseUrl}${this.endpoints.list}`).pipe(
            map(response => response.message),
            tap(items => {
                this._allItems.next(items);
                this.lastFetchTime = Date.now();
                if (items.length && !this._activeItem.getValue()) {
                    this._activeItem.next(items[0]);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('getList', this.endpoints.list, error);
            })
        );
    }

    create(
        data: Partial<T>,
        transformFn?: EntityTransformFn<T>
    ): Observable<T> {
        this._loading.next(true);
        return this.httpClient.post<{ message: T }>(`${this.baseUrl}${this.endpoints.create}`, data).pipe(
            map(response => response.message),
            tap(newItem => {
                const currentItems = this._allItems.getValue();
                const newItemTransformed = transformFn 
                    ? transformFn(newItem)
                    : newItem;
                this._allItems.next([...currentItems, newItemTransformed]);
                this._activeItem.next(newItemTransformed);
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('create', this.endpoints.create, error); 
            })
        );
    }

    update(
        id: string, 
        data: Partial<T>,
        transformFn?: EntityTransformFn<T>
    ): Observable<T> {
        this._loading.next(true);
        const endpoint = this.endpoints.update ? this.endpoints.update(id) : '';
        return this.httpClient.put<{ message: T }>(`${this.baseUrl}${endpoint}`, data).pipe(
            map(response => response.message),
            tap(updatedItem => {
                const currentItems = this._allItems.getValue();
                const updatedItemTransformed = transformFn 
                    ? transformFn(updatedItem)
                    : updatedItem;
                const updatedItems = currentItems.map(item => 
                    item.id === updatedItem.id ? { ...item, ...updatedItemTransformed } : item
                );

                this._allItems.next(updatedItems);
                if (this._activeItem.getValue()?.id === updatedItem.id) {
                    this._activeItem.next(updatedItemTransformed);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('update', endpoint, error);
            })
        );
    }

    delete(id: string): Observable<T> {
        this._loading.next(true);
        const endpoint = this.endpoints.delete ? this.endpoints.delete(id) : '';
        return this.httpClient.delete<{ message: T }>(`${this.baseUrl}${endpoint}`).pipe(
            map(response => response.message),
            tap(deletedItem => {
                const currentItems = this._allItems.getValue();
                const filteredItems = currentItems.filter(item => item.id !== deletedItem.id);
                this._allItems.next(filteredItems);
                
                if (this._activeItem.getValue()?.id === deletedItem.id) {
                    this._activeItem.next(filteredItems[0] || null);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('delete', endpoint, error);
            })
        );
    }

    // Enhanced error handling
    protected handleOperationError(operation: string, endpoint: string = '', error: HttpErrorResponse): Observable<never> {
        const errorMessage = error.error?.message || error.message || 'An error occurred';
        
        this.logger.error(`${operation} failed:`, {
            operation,
            endpoint,
            error: error.error,
            status: error.status,
            statusText: error.statusText
        });
        
        return throwError(() => new ServiceError(
            errorMessage,
            operation,
            endpoint,
            error
        ));
    }

    // State management
    clearState(): void {
        this._allItems.next([]);
        this._activeItem.next(null);
        this._loading.next(false);
        this.lastFetchTime = 0;
    }
}