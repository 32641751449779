import { inject, Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import { map, } from 'rxjs/operators';
import { UserApiObject, UserApiResponse } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { WebApiCacheService } from './cache-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private _cacheService = inject(WebApiCacheService);
  private _baseUrl = environment.apiBaseUrl;
  private _currentUserApiUrl : string = `${this._baseUrl}acc/user-mgmt/current`;

  /**
   * clearCache
   * 
   * To force reload data from backend when next time calling fetch data
   */
  public clearCache(): void {
    this._cacheService.clearCache(this._currentUserApiUrl);
  }

  
  /**
   * fetchCurrentUserData
   * 
   * Fetch the user data from the server and cached
   * so next call it will use the cached instead of re-fetch from the server
   */
  public fetchCurrentUserData(): Observable<UserApiObject | undefined> {
    return this._cacheService.fetchData<UserApiResponse | null>(this._currentUserApiUrl).pipe(
      map(response => response?.message)
    );
  }

}
