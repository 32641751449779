import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseGroupFilter, BaseGroupEntityService, GroupCrudEndpoints } from "@gci/components/base-page/base-group.service";
import { Role } from "app/models/role.type";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

// Define interfaces for type safety
interface RoleFilter extends BaseGroupFilter {
  // Add any specific filter properties for roles if needed
}


@Injectable({ providedIn: 'root' })
export class RoleService extends BaseGroupEntityService<Role, RoleFilter> {
    protected override endpoints: GroupCrudEndpoints = {
        list: (groupId: string) => 'default/role', 
    };

    constructor(protected override httpClient: HttpClient) {
        super(httpClient);        
    }

    /**
     * Get roles as an observable
     */
    get roles$(): Observable<Role[]> {
        return this.entities$;
    }

    /**
     * Set roles and update entities
     */
    set roles(data: Role[]) {
        this._entities.next(data);
    }

    /**
     * Get list of roles
     * @param forceRefresh Force refresh the cache
     * @returns Observable of Role array
     */
    getRoles(forceRefresh = false): Observable<Role[]> {
        // Using 'default' as groupId since the original endpoint was 'default/role'
        return this.getList('default', undefined, forceRefresh).pipe(
            tap(roles => {
                // Update the entities
                this.roles = roles;
            })
        );
    }
}