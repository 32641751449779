import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { Logger } from '@gci/helpers/logger';

@Injectable({
  providedIn: 'root',
})
export class WebApiCacheService {
  private _cacheMap = new Map<string, Observable<any>>(); // Cache for different API URLs
  private _http = inject(HttpClient);
  private _logger = new Logger('WebApiCacheService');
  
  /**
   * fetchData
   * 
   * Fetch data from backend when there is no cache
   * It will return from cache when exist
   */
  fetchData<T>(url: string): Observable<T> {
    // Check if cache exists for this URL and forceRefresh is not requested
    if (this._cacheMap.has(url)) {
      return this._cacheMap.get(url) as Observable<T>;
    }

    // If forceRefresh is true or no cache, make a new HTTP request
    const request$ = this._http.get<T>(url).pipe(
      tap(() => {
        this._logger.debug(`Fetched data from ${url}`);
      }),
      shareReplay(1) // Share the response with all subscribers
    );

    // Cache the observable
    this._cacheMap.set(url, request$);
    return request$;
  }

  /**
   * clearCache
   * 
   *
   * To clear the cache for a specific URL or all caches if not passing any argument
   */
  clearCache(url?: string): void {
    if (url) {
      this._cacheMap.delete(url);
    } else {
      this._cacheMap.clear();
    }
  }
}
