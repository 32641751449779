import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, from, lastValueFrom, Observable, of, Subject, takeUntil, throwError } from 'rxjs';
import { User } from '../user/user.types';
import { COGNITO_ATTR_COMPANY, COGNITO_ATTR_LANGUAGE, COGNITO_ID_USER_GROUP, COGNITO_ATTR_NAME, COGNITO_ATTR_EMAIL, COGNITO_ATTR_SUB, COGNITO_ATTR_ADDRESS } from 'app/models/cognito.constants';
import { UserApiService } from 'app/web-api/common/user-api.service';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { 
    AuthSession, fetchAuthSession, fetchUserAttributes, 
    signUp as authSignUp, confirmSignUp, autoSignIn as authAutoSignIn,
    signIn as authSignIn, signOut as authSignOut, confirmSignIn, 
    resetPassword as authResetPassword, confirmResetPassword, 
    ResetPasswordOutput, SignUpOutput, resendSignUpCode,
    ResendSignUpCodeOutput,
    ConfirmSignUpOutput,
    ConfirmSignUpInput,
    signInWithRedirect,
    updatePassword as authUpdatePassword
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Router } from '@angular/router';
import { AuthUserSignUp } from 'app/models/auth.type';
import { TranslocoService } from '@jsverse/transloco';
import { Logger } from '@gci/helpers/logger';
import { GroupService } from 'app/modules/admin/services/group.service';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _userApiService = inject(UserApiService);
    private _router = inject(Router);
    private _languageService = inject(TranslocoService);
    private _currentSession : AuthSession | undefined;
    private _currentUser!: User;
    private _userGroups: string[] = [];
    private _authenticated$ = new BehaviorSubject<boolean>(this._authenticated);
    private _forgotPasswordEmail!: string;
    private _forgotPasswordResult!: ResetPasswordOutput;
    private _signupUserEmail!: string;
    private _hubListenerCancelTokenCallback: any = null;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private  readonly _logger = new Logger('AuthService');

    constructor(
        private _groupService: GroupService
    ) {
        const config : ResourcesConfig = {
            Auth: {
                Cognito: {
                    userPoolClientId: environment.cognitoAppClientId,
                    userPoolId: environment.cognitoUserPoolId,
                    identityPoolId: environment.cognitoIdentityPoolId,
                    allowGuestAccess: false,
                    loginWith: {
                        oauth: {
                            domain: environment.oauthDomain,
                            scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                            redirectSignIn: environment.oauthRedirectSignIn,
                            redirectSignOut: environment.oauthRedirectSignOut,
                            responseType: 'code'
                        }
                    }
                },
            }
        }
        
        this._logger.debug('config', config);

        Amplify.configure(config);

        this._listenToAmplifyHubEvent();
    }

    ngOnDestroy(): void {
        this._hubListenerCancelTokenCallback(); // stop listen to Amplify Auth Hub Event
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get signUpEmail(): string {
        return this._signupUserEmail;
    }

    get currentUser(): User {
        return this._currentUser;
    }

    /**
     * authenticatedSubject
     * 
     * To get any changes to the authentication flag
     * you can subscribe to this subject
     * you will get an update of the authentication
     */
    get authenticatedSubject(): Subject<boolean> {
        return this._authenticated$;
    }

    /**
     * accessToken
     * 
     * To get current OAUTH Access Token from Cognito Current Session
     */    
    get accessToken(): string {
        if (!this._currentSession) {
            this._logger.debug("get accessToken _currentSession is null return empty accessToken");
            return '';
        }

        if (this._currentSession.tokens)
        {
            return this._currentSession.tokens.accessToken.toString();
        }

        return '';
    }

    /**
     * authenticated
     * 
     * To get current authentication status
     * If user has logged in, the value will be true
     * else false
     */    
    get authenticated(): boolean {
        return this._authenticated;
    }


    /**
     * forgotPasswordDestination
     * 
     * To get forgot password destination email
     * when user activate reset password, Auth Service will save the forgotPasswordResult
     * it contains destination
     */    
    get forgotPasswordDestination(): string | undefined
    {
        if (!this._forgotPasswordResult) {
            return '';
        }

        this._logger.debug("forgotPasswordDestination", this._forgotPasswordResult);

        return this._forgotPasswordResult.nextStep.codeDeliveryDetails.destination;
    }
    
    /**
     * forgotPasswordDestination
     * 
     * To get forgot password  email
     * when user activate forgot password, Auth Service will save the forgotPasswordEmail
     * it contains email that submitted to be reset 
     */    
    get forgotPasswordEmail(): string
    {
        return this._forgotPasswordEmail;
    }    

    /**
     * authenticated setter
     * 
     * To update _authenticated private value
     * Also it will update the _authenticated Subject
     */    
    private set authenticated(newVal: boolean) {
        this._authenticated = newVal;
        this._authenticated$.next(newVal);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _listenToAmplifyHubEvent() {
        this._hubListenerCancelTokenCallback = Hub.listen('auth', (data) => {
            const { payload, channel } = data;

            this._logger.log(data);

            switch (payload.event) {
                case 'signedIn':
                    this._logger.log('user have been signedIn successfully.');
                    break;
                case 'signedOut':
                    this._logger.log('user have been signedOut successfully.');
                    break;
                case 'tokenRefresh':
                    this._logger.log('auth tokens have been refreshed.');
                    break;
                case 'tokenRefresh_failure':
                    this._logger.log('failure while refreshing auth tokens.');
                    break;
                case 'signInWithRedirect':
                    this._logger.log('signInWithRedirect API has successfully been resolved.');
                    break;
                case 'signInWithRedirect_failure':
                    this._logger.log('failure while trying to resolve signInWithRedirect API.');
                    break;
                case 'customOAuthState':
                    this._logger.info('custom state returned from CognitoHosted UI');
                    // get customState return from federated login
                    // and set active language from the passing custom state
                    const customState = JSON.parse(payload.data);
                    // this._translateService.setActiveLang(customState.lang);

                    // const userReal = await this._cloudeUserService.getCurrentUser()
                    // this._currentUser = userReal.item

                    if (customState?.redirectUri) {
                        setTimeout(() => {
                            console.log(
                                'customState.redirectURL',
                                customState?.redirectUri
                            );
                            this._router.navigate([
                                customState?.redirectUri,
                            ]);
                        }, 800);
                    } else {
                        setTimeout(() => {
                            window.location.reload();
                        }, 800);
                    }
                    break;
              }
          });
    }

    /**
     * _updateAuthenticationAndUserData
     * 
     * It will retrieve cognito user data and stored in _currentUser
     * also set the UserService.user to _currentUser
     * and set _authenticated to true
     */
    private _updateAuthenticationAndUserData() : void {
        this._logger.debug("_updateAuthenticationAndUserData");

        if (this._currentSession && this._currentSession.tokens && this._currentSession.tokens.idToken) {
            // The session is valid, and we can get the ID token
            const idToken = this._currentSession.tokens?.idToken.toString();
            // Decode the ID token (using a library like jwt-decode)
            const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
            this._logger.debug("_updateAuthenticationAndUserData", "idToken", decodedToken);

            if (decodedToken[COGNITO_ID_USER_GROUP]) {
                this._userGroups = decodedToken[COGNITO_ID_USER_GROUP];
                this._logger.debug("_updateAuthenticationAndUserData", {"userGroups": this._userGroups});
            }
        }

        fetchUserAttributes().then(
            (attributes) => {
                this._logger.debug("_updateAuthenticationAndUserData", {"fetchUserAttributes": attributes});

                this._userApiService.fetchCurrentUserData()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                    next: (user) => {
                        if (!user) {
                            this._logger.error('fetchUserAttributes response null');
                            return;
                        }
    
                        const currentUser = user;
                        let defaultAvatar = 'images/avatars/female-18.jpg';
                        if( attributes.gender === 'Male') {
                            defaultAvatar = 'images/avatars/male-15.jpg';
                        }
    
                        this._currentUser = {
                            id: currentUser.id,
                            name: attributes.name,
                            email: attributes.email,
                            gender: attributes.gender ,
                            avatar: currentUser.avatar ? currentUser.avatar : defaultAvatar,
                            status: currentUser.status ? currentUser.status : 'online',
                            active: currentUser.active,
                            phone_number: currentUser.phone_number,
                            language: attributes[COGNITO_ATTR_LANGUAGE],
                            company: attributes[COGNITO_ATTR_COMPANY],
                            address: attributes[COGNITO_ATTR_ADDRESS]
                        }

                        this._logger.debug("_updateAuthenticationAndUserData", {"_currentUser": this._currentUser});
            
                        this._userService.user = this._currentUser;
                        this.authenticated = true;
                    },
                    error: (error) => {
                        this._logger.error("fetchCurrentUserData error", error);
                        this.authenticated = false;
                        return;       
                    }
                })
            },
            (error) => {
                this._logger.error("fetchUserAttributes error", error);
                this.authenticated = false;
            }
        )
    }


    /**
     * _getCurrentSessionAndValidate
     * 
     * It will get CognitoUserSession and validate
     * If the session is not valid, it will try to refreshSession using the session.refreshToken
     * If it's valid, it will save the session to _currentSession
     * and update the authenticated status and user information in User.Service
     */
    private _getCurrentSessionAndValidate = (): Observable<boolean> => {

        this._logger.debug("_getCurrentSessionAndValidate");

        return new Observable<boolean>((observer) => {
            fetchAuthSession().then(
                (session) => {
                    if (!session.tokens) {
                        this._logger.debug("_getCurrentSessionAndValidate fetchAuthSession tokens is undefined");
                        observer.next(false);
                        observer.complete();

                        return;
                    }

                    // check if cache session not exist or the idToken is not the same
                    // replace the cache session and update the UserData
                    if (!this._currentSession || this._currentSession.tokens?.idToken?.toString() != this._currentSession.tokens?.idToken?.toString()) {
                        this._logger.debug("_getCurrentSessionAndValidate fetchAuthSession session", session);

                        this._currentSession = session;
                        this._updateAuthenticationAndUserData();    
                    } else {
                        this._logger.debug("_getCurrentSessionAndValidate same session, SKIP _updateAuthenticationAndUserData");
                    }

                    observer.next(true);
                    observer.complete();                    
                },
                (error) => {
                    this._logger.error("_getCurrentSessionAndValidate fetchAuthSession error", error);
                    observer.next(false);
                    observer.complete();
                }
            )
        });
    }

    /**
     * _signIn
     * 
     * To start signIn process using Amplify Gen 2 library
     */
    private _signIn = (email: string, password: string): Observable<boolean> => {
        // let authenticationDetails: AuthenticationDetails = this._composeAuthenticationDetailsFromRequest(email, password);
        // let userData: ICognitoUserData = this._composeUserDataFromRequest(email);
        // const cognitoUser = new CognitoUser(userData);

        return new Observable<boolean>((observer) => {
            authSignIn({
                username: email,
                password: password
            }).then(
                (signInOutput) => {
                    fetchAuthSession().then(
                        (session) => {
                            this._currentSession = session;
                            this._logger.debug("_signIn Success session :", session);        
                            this._updateAuthenticationAndUserData();

                            if (signInOutput.nextStep.signInStep === "DONE") {
                                observer.next(true);
                                observer.complete();
                            } else {
                                this._forgotPasswordEmail = email;

                                this._logger.debug("_signIn authSignIn next step", signInOutput.nextStep.signInStep);

                                const step = signInOutput.nextStep.signInStep;

                                switch (step) {
                                    case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
                                        this._logger.debug("_signIn redirect to reset password");
                                        this._router.navigate(['/force-reset-password']);
                                            
                                        break;
                                    case "RESET_PASSWORD":
                                        this._logger.debug("_signIn redirect to reset password");
                                        this._router.navigate(['/reset-password']);
                                        break;
                                    
                                    case "CONFIRM_SIGN_UP":
                                        this._logger.debug("_signIn redirect to confirm sign up");
                                        this._signupUserEmail = email;
                                        this._router.navigate(['/sign-up-confirmation']);
                                        break;
                                
                                    default:
                                        break;
                                }

                                observer.next(false);
                                observer.complete();                                                
                            }
                        },
                        (error) => {
                            this._logger.error("_signIn fetchAuthSession error", error);
                            observer.error(error);
                            observer.complete();
                        }
                    )
                },
                (error) => {
                    this._logger.error("_signIn authSignIn error", error);

                    if (error.name=="UserLambdaValidationException" && error.message.includes("PreAuthentication failed with error email has not been verified")) { 
                        this._signupUserEmail = email;
                        this._router.navigate(['/sign-up-confirmation']);

                        observer.next(false);
                        observer.complete();
                    }

                    observer.error(error);
                    observer.complete();
                }
            )
        });
    }

    /**
     * _signUp
     * 
     * To start signUp process using Amplify Gen 2 library
     */
    private _signUp = (user: AuthUserSignUp): Observable<SignUpOutput> => {
        return new Observable<SignUpOutput>((observer) => {
            authSignUp({
                "username": user.email,
                "password": user.password,
                "options": {
                    "userAttributes": {
                        [COGNITO_ATTR_EMAIL]: user.email,
                        [COGNITO_ATTR_NAME]: user.name,
                        [COGNITO_ATTR_COMPANY]: user.company,
                        [COGNITO_ATTR_LANGUAGE]: this._languageService.getActiveLang()
                    }
                }
            }).then(
                (signUpOutput) => {
                    this._signupUserEmail = user.email;

                    observer.next(signUpOutput);
                    observer.complete();
                },
                (error) => {
                    this._logger.error("_signUp error", error);

                    observer.error(error);
                    observer.complete();
                }
            )
        });
    }

    /**
     * _confirmSignUp
     * 
     * To confirm SignUp with verification code using Amplify Gen 2 library
     */
    private _confirmSignUp = (user: ConfirmSignUpInput): Observable<ConfirmSignUpOutput> => {
        return new Observable<SignUpOutput>((observer) => {
            confirmSignUp(user).then(
                (confirmSignUpOutput) => {
                    observer.next(confirmSignUpOutput);
                    observer.complete();
                },
                (error) => {
                    this._logger.error("_confirmSignUp error", error);

                    observer.error(error);
                    observer.complete();
                }
            )
        });
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * loadSession
     * 
     * Helper function to load current user cognito session  
     */
    loadSession(): Promise<any> {
        this._logger.debug("loadSession");

        return lastValueFrom(this._getCurrentSessionAndValidate());
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        // return this._httpClient.post('api/auth/forgot-password', email);
        const promise = new Promise<ResetPasswordOutput>((resolve, reject) => {
            authResetPassword({
                "username": email
            }).then(
                (response: ResetPasswordOutput) => {
                    this._forgotPasswordEmail = email;
                    this._forgotPasswordResult = response;
                    resolve(response);
                },
                (error) => {
                    this._forgotPasswordEmail = '';
                    reject(error);
                }
            );
        });

        return from(promise);

    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(confirmationCode: string, password: string): Observable<any> {
        // return this._httpClient.post('api/auth/reset-password', password);

        return from(confirmResetPassword(
            {
                "username": this._forgotPasswordEmail,
                "confirmationCode": confirmationCode,
                "newPassword": password,
            })
        );
    }

    /**
     * forceChangePassword
     * 
     * To force change password when user created using Cognito console Amplify Gen 2 library
     */
    forceChangePassword(password: string): Observable<any> {
        this._logger.debug("forceChangePassword", {"password":password, "current-user":this._currentUser});

        return from(confirmSignIn({
            challengeResponse: password,
            options: {
                "userAttributes": {
                    COGNITO_ATTR_NAME: this._forgotPasswordEmail
                }
            }
        }));
    }


    /**
     * changePassword
     * 
     * To change password of the current user using Cognito console Amplify Gen 2 library
     * @param oldPassword
     * @param newPassword
     */
    changePassword(oldPassword: string, newPassword: string): Observable<any> {
        return from(authUpdatePassword({
            oldPassword: oldPassword, 
            newPassword: newPassword
        }));
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<boolean> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }

        return this._signIn(credentials.email, credentials.password);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Set the authenticated flag to false
        this._groupService.clearGroup()
        this.authenticated = false;
        this._userApiService.clearCache();
        return from(authSignOut());
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<SignUpOutput> {
        // return this._httpClient.post('api/auth/sign-up', user);
        return this._signUp(user);
    }

    /**
     * autoSignIn
     *
     * During Sign Up process when receive COMPLETE_AUTO_SIGN_IN on next step
     * call this to complete the Auto Sign In
     */
    autoSignIn(): Observable<any> {
        return from(authAutoSignIn());
    }

    /**
     * Confirm SignUp
     *
     * To confirm sign-up process by sending the confirmation code
     * @param user
     */
    confirmSignUp(user: {email: string; code: string}): Observable<ConfirmSignUpOutput> {
        return from(this._confirmSignUp({
            "username": user.email,
            "confirmationCode": user.code,
        }));
    }

    /**
     * Google Sign In
     *
     * To start Google Federation Sign In
     */
    googleSignIn(customState?: string): Observable<void> {
        return from(signInWithRedirect({
            "provider": "Google",
            "customState": customState
        })
    );
    }

    /**
     * Resend SignUp
     *
     * To trigger resend sign up code to destination email
     * 
     * @param email
     */
    resendSignUp(email: string): Observable<ResendSignUpCodeOutput> {
        return from(resendSignUpCode({
            "username": email,
        }));
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            this._logger.debug("AuthService check _authenticated");
            return of(true);
        }

        // If not authenticated
        return this._getCurrentSessionAndValidate();
    }
}


