import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Logger } from '@gci/helpers/logger';

export class ServiceError extends Error {
  constructor(
    message: string,
    public operation: string,
    public endpoint: string,
    public originalError: any
  ) {
    super(message);
    this.name = 'ServiceError';
    Error.captureStackTrace(this, ServiceError);
  }
}

export interface BaseGroupFilter {
  [key: string]: any;
}

export interface BaseGroupEntity {
  id: string;
  [key: string]: any;
}

export interface GroupEntityMap<T> {
  [key: string]: {
    data: T[];
    timestamp: number;
  };
}

export interface CacheConfig {
  cacheDuration: number;
}

export interface GroupCrudEndpoints {
  list?: (groupId: string) => string;
  create?: (groupId: string) => string;
  update?: (id: string, groupId?: string) => string;
  delete?: (id: string, groupId?: string) => string;
}

export type GroupEntityTransformFn<T> = (entity: T, response: T) => T;

@Injectable()
export abstract class BaseGroupEntityService<T extends BaseGroupEntity, F extends BaseGroupFilter = BaseGroupFilter> {
  protected baseUrl = environment.apiBaseUrl;
  protected abstract endpoints: GroupCrudEndpoints;

  protected cacheConfig: CacheConfig = {
    cacheDuration: 5 * 60 * 1000
  };

  protected _allEntities: BehaviorSubject<GroupEntityMap<T>> = new BehaviorSubject<GroupEntityMap<T>>({});
  protected _entities: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected _filter: BehaviorSubject<F> = new BehaviorSubject<F>({} as F);
  protected _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected _logger!: Logger;

  constructor(protected httpClient: HttpClient) {    
    this._logger = new Logger(this.constructor.name); // className will be _[className]
  }

  setCacheConfig(config: Partial<CacheConfig>) {
    this.cacheConfig = { ...this.cacheConfig, ...config };
  }

  set filter(filter: F) {
    this._filter.next(filter);
  }

  get filter$(): Observable<F> {
    return this._filter.asObservable();
  }

  get entities$(): Observable<T[]> {
    return this._entities.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  protected isCacheValid(groupId: string): boolean {
    const entities = this._allEntities.getValue();
    if (!entities[groupId]) return false;

    const currentTime = Date.now();
    const cacheAge = currentTime - entities[groupId].timestamp;
    return cacheAge < this.cacheConfig.cacheDuration;
  }

  getList(groupId: string, queryParams?: Record<string, string>, forceRefresh = false): Observable<T[]> {
    const entities = this._allEntities.getValue();
    
    if (!forceRefresh && this.isCacheValid(groupId) && entities[groupId]) {
      this._entities.next(entities[groupId].data);
      return of(entities[groupId].data);
    }

    this._loading.next(true);
    const endpoint = this.endpoints.list ? this.endpoints.list(groupId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
    
    return this.httpClient.get<{message: T[]}>(url).pipe(
      map(response => response.message),
      tap(response => {
        this._entities.next(response);
        const allEntities = this._allEntities.getValue();
        allEntities[groupId] = {
          data: response,
          timestamp: Date.now()
        };
        this._allEntities.next(allEntities);
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('getList', error);
      })
    );
  }

  create(body: Partial<T>, groupId: string, queryParams?: Record<string, string>): Observable<T> {
    this._loading.next(true);
    const endpoint = this.endpoints.create ? this.endpoints.create(groupId) : '';
    const url = `${this.baseUrl}${endpoint}${this.buildQueryString(queryParams)}`;
    
    return this.httpClient.post<{message: T}>(url, body).pipe(
      map(response => response.message),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        if (allEntities[groupId]) {
          allEntities[groupId] = {
            data: [response, ...allEntities[groupId].data],
            timestamp: allEntities[groupId].timestamp
          };
        } else {
          allEntities[groupId] = {
            data: [response],
            timestamp: Date.now()
          };
        }
        this._entities.next(allEntities[groupId].data);
        this._allEntities.next(allEntities);
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('create', error);
      })
    );
  }

  update(
    id: string, 
    body: Partial<T>, 
    groupId: string,
    transformFn?: GroupEntityTransformFn<T>
  ): Observable<T> {
    this._loading.next(true);
    const endpoint = this.endpoints.update ? this.endpoints.update(id, groupId) : '';
    const url = `${this.baseUrl}${endpoint}`;

    return this.httpClient.put<{message: T}>(url, body).pipe(
      map(response => response.message),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        if (allEntities[groupId]) {
          const entityIndex = allEntities[groupId].data.findIndex(e => e.id === id);
          if (entityIndex !== -1) {
            const updatedEntity = transformFn 
              ? transformFn(allEntities[groupId].data[entityIndex], response)
              : response;

            allEntities[groupId].data[entityIndex] = updatedEntity;
            this._entities.next(allEntities[groupId].data);
            this._allEntities.next(allEntities);
          }
        }
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('update', error);
      })
    );
  }

  delete(id: string, groupId: string, body?: any): Observable<T> {
    this._loading.next(true);
    const endpoint = this.endpoints.delete ? this.endpoints.delete(id, groupId) : '';
    const url = `${this.baseUrl}${endpoint}`;
    const options = body ? { body } : undefined;

    return this.httpClient.delete<{message: T}>(url, options).pipe(
      map(response => response.message),
      tap(response => {
        const allEntities = this._allEntities.getValue();
        if (allEntities[groupId]) {
          allEntities[groupId] = {
            data: allEntities[groupId].data.filter(item => item.id !== id),
            timestamp: allEntities[groupId].timestamp
          };
          this._entities.next(allEntities[groupId].data);
          this._allEntities.next(allEntities);
        }
        this._loading.next(false);
      }),
      catchError(error => {
        this._loading.next(false);
        return this.handleOperationError('delete', error);
      })
    );
  }

  clearCache(): void {
    this._allEntities.next({});
    this._entities.next([]);
  }

  protected handleOperationError(operation: string, error: HttpErrorResponse) {
    const errorMessage = error.error?.message || error.message || 'Unknown error occurred';
    console.error(`${operation} failed:`, {
      operation,
      endpoint: this.endpoints,
      error: error.error,
      status: error.status,
      statusText: error.statusText
    });
    
    return throwError(() => new ServiceError(
      errorMessage,
      operation,
      JSON.stringify(this.endpoints),
      error
    ));
  }

  protected buildQueryString(params?: Record<string, string>): string {
    if (!params) return '';
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });
    return queryParams.toString() ? `?${queryParams.toString()}` : '';
  }

  refreshCache(groupId: string, queryParams?: Record<string, string>): Observable<T[]> {
    return this.getList(groupId, queryParams, true);
  }
}